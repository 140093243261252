@use 'assets/styles/utils/helper' as h;

.tve-notice__label {
  width: 100%;

  @include h.breakpoint-l {
    width: 70%;
  }
  text-align: center;
}
