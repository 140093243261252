/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
// This file is an edited version of _mixins.scss.
// Some functions in the original file will break
// when importing directly into a component's stylesheet
@use 'sass:math';
@use 'sass:map';
@use 'sharedvariables' as sharedvariables;
@use 'functions';

@mixin breakpoint-s-only {
  @media only screen and (max-width: (sharedvariables.$grid-m-width - 1)) {
    @content;
  }
}

@mixin breakpoint-m {
  @media only screen and (min-width: sharedvariables.$grid-m-width) {
    @content;
  }
}

@mixin breakpoint-m-only {
  @media only screen and (min-width: sharedvariables.$grid-m-width)
    and (max-width: (sharedvariables.$grid-l-width - 1)) {
    @content;
  }
}

@mixin breakpoint-m-max {
  @media only screen and (max-width: (sharedvariables.$grid-l-width - 1)) {
    @content;
  }
}

@mixin breakpoint-l {
  @media only screen and (min-width: sharedvariables.$grid-l-width) {
    @content;
  }
}

@mixin breakpoint-l-only {
  @media only screen and (min-width: sharedvariables.$grid-l-width)
    and (max-width: (sharedvariables.$grid-x-width - 1)) {
    @content;
  }
}

@mixin breakpoint-m-to-l-only {
  @media only screen and (min-width: sharedvariables.$grid-m-width)
    and (max-width: (sharedvariables.$grid-x-width - 1)) {
    @content;
  }
}

@mixin breakpoint-x {
  @media only screen and (min-width: sharedvariables.$grid-x-width) {
    @content;
  }
}

@mixin breakpoint-xx {
  @media only screen and (min-width: sharedvariables.$grid-xx-width) {
    @content;
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// Reusable IE-only styling, flex-basis is prioritized over flex-shrink in IE (matches spec)
// https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
@mixin ie-flex-basis {
  @include ie {
    flex: 1 1 0% !important; // must override parent's direct descendant selector (0 0 auto)
  }
}

@mixin pattern-dots($color, $background-color: transparent) {
  background-color: $background-color;
  background-image: radial-gradient($color 1px, transparent 1px);
  background-size: 5px 5px;
  background-position: 1px 1px;
}

@mixin pattern-dots-border($background-image-color: $grey-60, $background-position: top) {
  background-image: linear-gradient(to right, $background-image-color 10%, transparent 20%);
  background-size: 5px 1px;
  background-repeat: repeat-x;
  background-position: $background-position;
}

// creates a slanted underline design under title text
// for use with TODAY brand
@mixin title-underline($background-color: var(--accent-yellow), $font-size: 24px, $line-height: 1) {
  z-index: 2;
  display: inline;
  position: relative;
  // fixes breaking style in Firefox when there's multi-line text
  @supports (-moz-appearance:none) {
    display: inline-block;
  }

  &::after {
    background-color: $background-color;
    bottom: calc(#{$font-size} * #{$line-height} * .11);
    left: 0;
    clip-path: polygon(0 0, 98% 60%, 100% 100%, 0 100%);
    content: '';
    height: calc(#{$font-size} * #{$line-height} * .33);
    max-width: calc(-30px + 100vw);
    position: absolute;
    width: calc(100% + #{functions.rem(30px)});
    z-index: -1;
    box-sizing: border-box;

    @include breakpoint-m {
      width: calc(100% + #{functions.rem(40px)});
      max-width: none;
    }

    @include breakpoint-l {
      width: calc(100% + #{functions.rem(50px)});
    }
  }
}

// adds hover effects and styles for arrow icon to linked title
@mixin title-underline-link($hover-bkg: var(--accent-blue), $font-size: 28px) {
  &::after {
    transition: background-position .5s ease;
    background-position: -600px 0;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    width: calc(100% + #{functions.rem(25px)});

    @include breakpoint-m {
      width: calc(100% + #{functions.rem(30px)});
      max-width: none;
    }

    @include breakpoint-l {
      width: calc(100% + #{functions.rem(40px)});
    }
  }

  &:hover::after {
    background-image: linear-gradient(70deg, $hover-bkg 50%, #0000 50%);
    background-position: 0 0;
  }

  // styles for arrow icon
  .icon-chevron-right {
    display: inline-block;
    vertical-align: baseline;
    margin: #{functions.rem(6px) 0 0 functions.rem(6px)};
    font-size: #{functions.rem(28px)};

    &::before {
      color: var(--primary-color);
      margin: 0;
      font-size: #{$font-size};
    }
  }

  // this part for css modules has to be duplicated
  // since it's not compiled properly when used with
  // regular css classes separated by comma as: .icon-chevron-right,:global(.icon-chevron-right) {}
  :global(.icon-chevron-right) {
    display: inline-block;
    vertical-align: baseline;
    margin: #{functions.rem(6px) 0 0 functions.rem(6px)};
    font-size: #{functions.rem(28px)};

    &::before {
      color: var(--primary-color);
      margin: 0;
      font-size: #{$font-size};
    }
  }
}

@mixin border-gradient($colors, $direction: right) {
  border-image: linear-gradient(to $direction, $colors) 1;
}

@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin fade-on-hover {
  &:hover {
    opacity: var(--default-hover-opacity);
  }

  &:active {
    opacity: var(--default-active-opacity);
  }

  &:visited {
    opacity: 1;
  }
}

@mixin responsive-variations($rule, $variationMap) {
  @if functions.is-map($variationMap) {
    @each $size, $variation in $variationMap {
      @if $size == 's' {
        #{$rule}: map.get($variationMap, 's');
      } @else if $size == 'm' {
        @include breakpoint-m {
          #{$rule}: map.get($variationMap, 'm');
        }
      } @else if $size == 'l' {
        @include breakpoint-l {
          #{$rule}: map.get($variationMap, 'l');
        }
      } @else if $size == 'x' {
        @include breakpoint-x {
          #{$rule}: map.get($variationMap, 'x');
        }
      } @else if $size == 'xx' {
        @include breakpoint-xx {
          #{$rule}: map.get($variationMap, 'xx');
        }
      } @else {
        @warn 'Unexpected size #{$size} passed to mixin';
      }
    }
  } @else {
    #{$rule}: $variationMap;
  }
}

@mixin map-to-responsive-rules($valueMap) {
  @if functions.is-map($valueMap) {
    @each $rule, $value in $valueMap {
      @include responsive-variations($rule, $value);
    }
  }
}

/*
  Used for the object-fit-images polyfill: https://github.com/fregante/object-fit-images
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;

  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

/// Ellipsify content after a certain number of lines
/// Fallback uses gradient to fade out content instead of ellipses
/// !Note that you will likely need to manually handle the overflow rules
/// !to achieve your desired result
/// @param {number} $maxNumberOfLines - The maximum number of lines to display
/// @param {px} $fontSize - The font size (or line-height) of the content (necessary for descenders and the fallback calculation)
/// @param {color} $fontSize - The font size of the content (necessary for descenders and the fallback calculation)

@mixin line-clamp($maxNumberOfLines, $fontSize, $background, $overrideLineHeight: true) {
  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: $fontSize * $maxNumberOfLines; // always place at start of second line
    width: 3em;
    height: 1em;
    margin-top: 0.2em;
    background: linear-gradient(90deg, rgba($background, 0) 10%, rgba($background, 1) 50%, rgba($background, 1));
  }

  @supports (-webkit-line-clamp: $maxNumberOfLines) and (-webkit-box-orient: vertical) and (display: -webkit-box) {
    // stylelint-disable comment-empty-line-before
    // * autoprefixer removes the -webkit-box-orient rule, but it's necessary for the line-clamp effect

    /*! autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $maxNumberOfLines;
    -webkit-box-orient: vertical;
    // stlyelint-enable comment-empty-line-before
    text-overflow: ellipsis;

    @if ($overrideLineHeight) {
      // * Add 1px to handle descendors (portions of letters that descend below type baseline)
      line-height: $fontSize + 1px;
    }

    &::after {
      display: none;
    }
  }
}

@mixin direct-headings {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    @content;
  }
}

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin package-spacing {
  margin-bottom: var(--package-spacing);
}

@mixin flex-tease-space($width, $teases) {
  @include breakpoint-m {
    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }

  @if $teases == 4 {
    flex-basis: calc(#{$width} - 1.5px);

    &:first-of-type {
      margin-right: unset;
    }

    &:last-of-type {
      margin-left: unset;
    }
  } @else if $teases == 3 {
    flex-basis: calc(#{$width} - 1.33px);
  } @else {
    flex-basis: calc(#{$width} - 1px);
  }
}

@mixin css-reset-btn {
  border: none;
  background: transparent;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  color: inherit;
}

@mixin grid-custom-column($num, $tot: 12) {
  width: math.div(100%, $tot) * $num;
}

@mixin grid-custom-push($push, $tot: 12) {
  margin-left: math.div(100%, $tot) * $push;
}

@mixin no-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

@mixin gated-gradient {
  position: relative;

  &::after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
    content: '';
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

@mixin slanted-background {
  overflow: initial;
  position: relative;

  &::before {
    background: var(--grey-20);
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    height: functions.rem(215px);
    left: 50%;
    transform: translateX(-50%) rotate(2.3deg) skew(2.3deg);

    @include responsive-variations(top, (s: functions.rem(32px), m: functions.rem(48px), l: functions.rem(55px)));

    @include breakpoint-m {
      height: functions.rem(250px);
    }
  }
}

@mixin package-collection-title {
  :global(.news .hasTitle) &,
  :global(.think .hasTitle) &,
  :global(.mach .hasTitle) &,
  :global(.better .hasTitle) &,
  :global(.msnbc .hasTitle) & {
    @include breakpoint-l {
      margin-top: functions.rem(60px);
    }

    @include breakpoint-x {
      margin-top: functions.rem(50px);
    }
  }
}
