@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

@include h.ie {
  .alert-banner {
    display: block !important;
    font-family: var(--alert-banner-text-font-family);
    font-size: var(--alert-banner-font-size-s);
    line-height: var(--alert-banner-text-leading);
    background-color: var(--alert-banner-background-color);
    color: h.$msnbc-alt-red-70;
    position: relative;
    z-index: 11;
    overflow: hidden;
    padding: h.rem(20px 40px 20px 20px);
    width: 100vw;
    text-align: center;

    @include h.breakpoint-s-only {
      text-align: left;
    }

    &__close-button {
      position: absolute;
      top: var(--space-12);
      right: var(--space-12);
      cursor: pointer;
      background: none;
      border: none;
      border-radius: none;
      color: var(--msnbc-alt-red-70);
      padding: h.rem(8px 40px 20px 20px);

      @include h.breakpoint-s-only {
        padding: h.rem(24px 8px 20px 20px);
      }
    }
  }
}
