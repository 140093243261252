@use 'sass:math';
@use 'functions';

// Brand-agnostic variables used in multiple themed files go here
$big-headline-tease-top-offset: 48px;
$outer-gutter: 20px;
$outer-gutter-desktop: 40px;
$neg-outer-gutter: -20px;

$grid-s-width: 375px;
$grid-m-width: 758px;
$grid-l-width: 1000px;
$grid-x-width: 1240px;
$grid-xx-width: 1600px;

$package-spacing: functions.rem(48px);

$twelve-col-percentage: math.div(100%, 12);

$gutter-mobile: 2vw;
$gutter-tablet: 4vw;
$gutter-desktop: 40px;

$grid-col-width-mobile: calc(#{$twelve-col-percentage} - #{$gutter-mobile});
$grid-col-width-tablet: calc(#{$twelve-col-percentage} - #{$gutter-tablet});
$grid-col-width-desktop: calc(#{$twelve-col-percentage} - #{$gutter-desktop});

$grid-margin-mobile: calc(#{$outer-gutter} - #{$gutter-mobile});
$grid-margin-tablet: calc(#{$outer-gutter} - #{$gutter-tablet});
$neg-grid-margin-mobile: calc((#{$outer-gutter} - #{$gutter-mobile}) * -1);
$neg-grid-margin-tablet: calc((#{$outer-gutter} - #{$gutter-tablet}) * -1);

:root {
  --twelve-col-percentage: #{$twelve-col-percentage};
  --package-spacing: 3rem;
  --outer-gutter: #{$outer-gutter};
  --bp-m-width: #{$grid-m-width};
  --bp-l-width: #{$grid-l-width};
  --bp-x-width: #{$grid-x-width};
  --grid-container-m: calc(100% - 2 * var(--outer-gutter));
  --grid-container-l: calc(var(--bp-l-width) - 2 * var(--outer-gutter));
  --grid-container-x: calc(var(--bp-x-width) - 2 * var(--outer-gutter));
  --inner-gutter-s: #{$gutter-mobile};
  --inner-gutter-m: #{$gutter-tablet};
  --inner-gutter-l: #{$gutter-desktop};
  --inner-gutter-x: #{$gutter-desktop};

  @media screen and (min-width: $grid-l-width) {
    --outer-gutter: #{$outer-gutter-desktop};
  }
}
