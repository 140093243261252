@use 'assets/styles/utils/helper' as h;

.footerDefault {
  position: relative;
  background: var(--grey-70);
  padding: h.rem(100px 0 50px);
  clear: both;

  :global(.news) & {
    background-color: var(--blue-70);
  }

  :global(.msnbc) & {
    background-color: var(--default-color);
  }

  @include h.breakpoint-m {
    padding: h.rem(55px 40px 40px) 18.5%;
  }

  @include h.breakpoint-l {
    padding-top: h.rem(50px);
    padding-left: h.rem(140px);
    padding-right: calc(((100vw - 1000px) / 2) + 40px);
  }

  @include h.breakpoint-x {
    padding-right: 25px;
  }
}

.row {
  position: relative;
  display: block;
  padding: h.rem(0 h.$outer-gutter);

  @include h.breakpoint-m {
    padding: 0;
  }
}

.backdropLogo {
  color: var(--white);
  position: absolute;
  top: h.rem(38px);
  left: h.rem(h.$outer-gutter);

  &::before {
    font-size: h.rem(23px);
  }

  @include h.breakpoint-l {
    top: h.rem(36px);
    left: h.rem(h.$outer-gutter);
  }
}

.copyright {
  font-weight: normal;
  color: var(--grey-50);
  padding: 0;
  margin: 0;

  :global(.today) &,
  :global(.msnbc) & {
    color: var(--white);
    opacity: 0.5;
  }
}
