@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.layout-header {
  transition: transform 0.25s ease, top 0.25s ease;

  .hfsh {
    // Allow global nav to pass under banner advert
    // Allow nav to appear above Taboola elements
    z-index: var(--layout-header-z-index);

    &.search-open {
      // Search overlay appears over banner advert
      z-index: 20000000;
    }

    &.has-local-insert .local-insert {
      @include h.breakpoint-m {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: var(--default-local-insert-flex);
      }
    }

    &.scrolled > .local {
      margin-bottom: 0;
    }
  }

  &__default-navbar {
    background-color: var(--layout-header-default-background-color);
    line-height: var(--navbar-mobile-height);
    height: var(--navbar-mobile-height);

    @include h.breakpoint-m {
      line-height: var(--navbar-default-height);
      height: var(--navbar-default-height);
    }
  }

  &__default-logo::after {
    background-color: var(--layout-header-default-background-color);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }

  .telemundo &.show-elections-nav#hfs-header .hfsh.init + .hfsh-spacer,
  .noticias &.show-elections-nav#hfs-header .hfsh.init + .hfsh-spacer,
  .deportes &.show-elections-nav#hfs-header .hfsh.init + .hfsh-spacer,
  .entretenimiento &.show-elections-nav#hfs-header .hfsh.init + .hfsh-spacer,
  .shows &.show-elections-nav#hfs-header .hfsh.init + .hfsh-spacer {
    // 80px (nav height) + 40px (elections nav height)
    height: calc(var(--main-nav-height) + var(--election-nav-height, 0));
  }

  &.show-elections-nav#hfs-header .hfsh.init.small + .hfsh-spacer {
    // 60px (nav height) + 42px (elections nav height) - 5px (negative margin)
    height: calc(var(--main-nav-height) + var(--election-nav-height, 0) - 5px);
  }

  &.show-elections-nav#hfs-header {
    background-color: var(--layout-header-default-background-color);
    height: calc(var(--main-nav-height) + var(--election-nav-height, 0) - 5px);
  }
}

body:has(div#electionsNav) .layout-header .hfsh.scrolled > .local {
  box-shadow: none;
}

#hfs-header.notSticky {
  position: relative;
  min-height: 60px;
}

#hfs-header.notSticky .hfsh {
  position: relative;
}
