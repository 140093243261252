:global(.hfsh) {
  .electionsNav {
    padding-bottom: 32px;
    pointer-events: auto;
    // Delay margin change on menu open
    transition: margin 0s 0.25s;
  }

  &:global(:not(.menu-open)) {
    .electionsNav {
      // Change margin immediately on menu close
      transition: none;
    }
  }
}
