@use 'assets/styles/utils/helper' as h;

:root {
  --sticky-ad-top-offset: #{h.rem(90px)};
}

.news {
  --sticky-ad-top-offset: #{h.rem(170px)};
}

.today {
  --sticky-ad-top-offset: #{h.rem(140px)};
}

.msnbc {
  --sticky-ad-top-offset: #{h.rem(140px)};
}
