@use 'assets/styles/utils/helper' as h;

.mini-player {
  // Wrapper
  --miniplayer__default__medium-bp--max-width: #{h.rem(400px)};

  // Headline
  --miniplayer__default__headline--font-size: var(--text-18);
  --miniplayer__default__headline--color: var(--grey-40);

  // Action
  --miniplayer__default__action--background-color: var(--default-color);
  --miniplayer__default__action--color: var(--white);

  // Logo
  --miniplayer__default__logo--fill: var(--grey-40);
  --miniplayer__default__logo-alt--fill: var(--grey-40);
  --miniplayer__default__logo--font-size: #{h.rem(12px)};
  --miniplayer__default__logo--margin-bottom: #{h.rem(5px)};

  // Signin lock
  --miniplayer__default__signin--font-size: var(--text-18);

  // Light 'theme'
  --miniplayer__light--background-color: var(--grey-20);
  --miniplayer__light--margin-bottom: #{h.rem(30px)};
  --miniplayer__light__headline--color: var(--black);
  --miniplayer__light__logo--fill: var(--black);
  --miniplayer__light__logo--margin-bottom: #{h.rem(12px)};

  // MSNBC
  body.msnbc & {
    // Wrapper
    --miniplayer__default__medium-bp--max-width: auto;

    // Headline
    --miniplayer__default__headline--font-size: var(--text-16);
    --miniplayer__default__headline--color: var(--white);

    // Action
    --miniplayer__default__action--background-color: var(--yellow-40);
    --miniplayer__default__action--color: var(--black);

    // Logo
    --miniplayer__default__logo--margin-bottom: #{h.rem(8px)};

    // Signin lock
    --miniplayer__default__signin--font-size: var(--text-16);
  }

  // Today
  body.today & {
    --miniplayer__default__logo--fill: var(--white);
    --miniplayer__default__logo-alt--fill: var(--orange-30);
  }
}

.mini-tease-local {
  // Minitease in local nav
  --miniplayer__local__width: auto;

  @include h.breakpoint-m {
    --miniplayer__local__width: auto;
  }

  --miniplayer__local__logo__height: #{h.rem(12px)};
  --miniplayer__local__margin: #{h.rem(0 32px 0 0)};
  --miniplayer__local__live__margin: #{h.rem(-2px 2px 0 0)};
  --miniplayer__local__dot__color: var(--breaking-red);
  --miniplayer__local__dot__color--light: var(--breaking-red);
  --miniplayer__local__dot__color--transparentLight: var(--breaking-red);
  --miniplayer__local__default__logo--fill: var(--white);
  --miniplayer__local__default__logo--fill--light: var(--grey-70);
  --miniplayer__local__default__logo--fill--light--hover: var(--default-color);
  --miniplayer__local__default__logo--fill--transparentLight: var(--grey-70);
  --miniplayer__local__default__logo--fill--transparentLight--hover: var(--default-color);

  body.msnbc & {
    --miniplayer__local__width: #{h.rem(56px)};
    --miniplayer__local__live__margin: #{h.rem(1px 2px 0 0)};
  }

  body.today & {
    --miniplayer__local__width: #{h.rem(100px)};
    --miniplayer__local__live__margin: #{h.rem(0 4px 0 0)};
    --miniplayer__local__dot__color: var(--white);
    --miniplayer__local__default__logo--fill--light--hover: var(--orange-30);
    --miniplayer__local__default__logo--fill--transparentLight--hover: var(--orange-30);
  }

  body.news & {
    --miniplayer__local__live__margin: #{h.rem(0 3px 0 0)};
  }

  body.noticias &,
  body.telemundo & {
    --miniplayer__local__width: #{h.rem(50px)};
    --miniplayer__local__live__margin: #{h.rem(1px 2px 0 0)};
  }
}
