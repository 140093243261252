@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.ad.ad--sticky {
  position: sticky;
  width: 100%;
  top: var(--sticky-ad-top-offset);
}

div[data-mps-slot^='interstitial'] {
  height: 0;
}

@include h.breakpoint-s-only {
  div[data-mps-slot^='boxinline'] {
    min-height: h.rem(320px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div[data-mps-slot^='boxinline'] > div {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.ad--text-alignment {
  text-align: center;

  @include h.breakpoint-m {
    text-align: left;
  }
}

.ad--centered {
  text-align: center;
  overflow: hidden;

  @include h.breakpoint-m {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
  }
}

.boxinlineAd {
  &--50 {
    min-height: 50px;
  }

  &--250 {
    min-height: 250px;
  }

  @include h.breakpoint-l {
    min-height: 0;
  }

  &.bottomLineBoxLineAd {
    margin: 0 20px 20px;

    @include h.breakpoint-s-only {
      border-bottom: 1px solid var(--grey-50);
    }
  }
}

.box-inline-border {
  @include h.breakpoint-m {
    display: none;
  }
  padding: 0 20px 20px;
}

// class only applied for Curator preview
.ad--preview > div > div:empty {
  position: relative;
  font-family: sans-serif;
  border: 2px var(--grey-50) solid;
  width: 250px;
  height: 200px;
  margin: 0 auto;

  &::before {
    content: '[Ad Placeholder]';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--grey-50);
  }
}
