.override {
  // TODO: fix this in HFS
  &:global(.light.active.scrolled) {
    :global(.today) & {
      :global(.headline-title-text) {
        color: #380538;
      }
    }
  }
}
