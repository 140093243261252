@use 'assets/styles/utils/helper' as h;

$width-m: 612px !default;
$width-l: 660px !default;
$width-x: 700px !default;

.tve__provider-select {
  --tve-provider-select-action-color: var(--default-color);
  --tve-provider-select-action-hover: var(--default-color);
  --tve-provider-select-action-active: var(--default-color);

  body.msnbc & {
    --tve-provider-select-action-color: var(--yellow-40);
    --tve-provider-select-action-hover: var(--yellow-20);
    --tve-provider-select-action-active: var(--yellow-10);
  }
}
