@use 'assets/styles/utils/helper' as h;

.marqueeBreaking {
  --marquee--background-color: var(--red-50);
  --marquee--color: var(--white);
  --marquee--line-height: var(--leading-100);
  --marquee--font-family: var(--default-headline-font-family);
  --marquee--font-weight: inherit;
  --marquee--font-size: var(--text-24);
  --marquee--min-height: auto;
  --marquee--padding: var(--space-20);
  --marquee--text-transform: none;

  // Inner element
  --marquee--inner--max-width: auto;

  // Type icon
  --marquee--icon--font-size: var(--text-16);
  --marquee--icon--margin-left: var(--space-12);

  // Close button
  --marquee--close-button--display: block;
  --marquee--close-button-svg--height: var(--space-16);
  --marquee--close-button-svg--width: var(--space-16);
  --marquee--close-button-svg--stroke-color: var(--white);
  --marquee--close-button-svg--stroke-width: 1;

  @include h.breakpoint-m {
    --marquee-padding: var(--space-24) var(--space-20);
    --marquee--close-button--display: none;
  }

  @include h.breakpoint-l {
    --marquee--font-size: var(--text-32);
    --marquee--icon--font-size: var(--text-24);
  }

  // Variable overrides by brand
  :global(body.news) &,
  :global(body.msnbc) &,
  :global(body.today) & {
    --marquee--font-size: var(--text-20);
    --marquee--min-height: var(--space-64);
    --marquee--padding: var(--space-16) var(--space-20) var(--space-16) var(--space-12);

    // Inner element
    --marquee--inner--max-width: 1280px;

    // Type icon
    --marquee--icon--font-size: var(--text-12);
    --marquee--icon--margin-left: var(--space-4);

    // Close button
    --marquee--close-button-svg--height: var(--space-20);
    --marquee--close-button-svg--width: var(--space-20);
    --marquee--close-button-svg--stroke-width: 2;

    @include h.breakpoint-m {
      --marquee--min-height: auto;
      --marquee--padding: 14px 16px;
      --marquee--icon--margin-left: var(--space-8);
    }
  }

  // Today has similar spacing but a different background color.
  // Spacing rules are shared above.
  :global(body.today) & {
    --marquee--font-family: var(--secondary-font);
    --marquee--font-size: var(--text-18);
    --marquee--line-height: var(--text-18);
    --marquee--font-weight: 700;
    --marquee--padding: 18px 16px;
    --marquee--text-transform: uppercase;
    --marquee--background-color: var(--accent-yellow-light);
    --marquee--color: var(--grey-70);
    --marquee--close-button-svg--stroke-color: var(--grey-70);

    &.breakingNews {
      --marquee--background-color: var(--grey-60);
      --marquee--color: var(--white);
      --marquee--close-button-svg--stroke-color: var(--white);
    }
  }

  :global(body.sponsoredcontent) & {
    --marquee--font-family: var(--publico-hed);
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.shows) &,
  :global(body.entrenenimiento) & {
    --marquee--background-color: #fa3a3a;

    @include h.breakpoint-m {
      --marquee--padding: var(--space-24) var(--space-20);
    }
  }
}
