@use 'assets/styles/utils/helper' as h;

.ad-sponsor {
  display: none;
  height: 100%;
  visibility: hidden;

  @include h.breakpoint-l {
    visibility: visible;
  }
}

.ad-sponsor--displayed,
// Specificity to override header styling
.hfsh .ad-sponsor--displayed {
  @include h.breakpoint-l {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: var(--space-24);
  }
}

.ad-sponsor__label,
// Specificity to override header styling
.hfsh .ad-sponsor__label {
  color: var(--white);
  display: block;
  font-size: var(--text-10);
  margin-right: var(--space-8);
  opacity: var(--opacity-70);
  text-transform: uppercase;
}

.hfsh.light .ad-sponsor__label,
.hfsh.active .ad-sponsor__label,
.hfsh.menu-open .ad-sponsor__label {
  color: var(--black);
}

.ad-sponsor__utility {
  display: inline-block;
  line-height: 1;
}

// Ad sponsor logo should not appear when menu is open
.hfsh.menu-open .ad-sponsor {
  display: none;
}
