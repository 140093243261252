@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.header-and-footer--banner-ad {
  text-align: center;
  background: var(--header-and-footer-background-color);
  z-index: 99999;
  backface-visibility: initial;
  opacity: 1;
  top: 0;
  display: var(--header-and-footer-ad-container-display);
  padding: var(--header-and-footer-ad-container-padding);
  min-height: var(--header-and-footer-ad-container-min-height);
  align-items: center;
  justify-content: center;
  position: relative;

  &.header-and-footer--static {
    z-index: 99999;
  }
}

.landing-header-footer-banner-ad {
  background: var(--landing-topbanner-background-color-loading);
  min-height: 122px;
  padding-top: 16px;
  padding-bottom: 20px;

  &.header-and-footer--static {
    z-index: 999;
    background: var(--landing-topbanner-background-color-loaded);
    border-bottom: none;
    padding-top: 16px;
    padding-bottom: 20px;
  }
}

.landing-slide-animation {
  z-index: 999;
  width: 100%;
  animation: 0.75s cubic-bezier(0.8, 0, 0.58, 1) 10s 1 normal forwards paused slideUp;
  position: sticky;
  top: 60px;

  &.landing-animation-scrolled {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebeb !important;
  }

  &.animation-delay {
    animation-play-state: running;
    animation-delay: 3.75s;
    background: var(--landing-topbanner-background-color-loaded);
  }

  &.hide-animation {
    background-color: #fff;
    animation-play-state: paused;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
    z-index: 2;
  }
}

.ad-placeholder {
  z-index: -1;
  position: absolute;
  top: var(--advertisement-top);
  left: var(--advertisement-left);
  transform: var(--advertisement-transform);
  font-size: var(--advertisement-font-size);
  color: var(--advertisement-text-color);
  font-family: var(--advertisement-font-family);
}
