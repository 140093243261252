@use 'assets/styles/utils/helper' as h;

.trendingNavContainer {
  position: unset;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include h.breakpoint-m {
    position: relative;
  }

  button.trendingNavButton {
    cursor: pointer;
    text-align: center;
    background-color: transparent;
    min-width: h.rem(20px);
    min-height: h.rem(20px);
    padding-top: h.rem(6px);
    border-bottom: solid h.rem(3px);
    border-color: transparent;

    &.open {
      border-color: #ff503c;
    }

    svg {
      fill: #2a2a2a;
      transition: fill 0.125s;
    }

    &.darkTheme {
      svg {
        fill: #ebebeb;
      }
    }

    &:hover {
      svg {
        fill: #ff503c;
      }
    }
  }

  nav.trendingNav {
    height: auto;
    width: calc(100vw - h.rem(20px));
    left: h.rem(10px);
    top: h.rem(50px);
    position: absolute;
    background-color: var(--neutral-orange);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 99999;
    padding-bottom: h.rem(50px);
    max-height: calc(100vh - h.rem(50px));
    overflow-y: scroll;

    @include h.breakpoint-m {
      width: h.rem(355px);
      top: h.rem(50px);
      left: h.rem(-205px);
    }

    @include h.breakpoint-x {
      left: h.rem(-170px);
    }
  }

  header.trendingHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: h.rem(28px);
    width: 100%;
    background-color: transparent;
    height: h.rem(20px);
    margin-top: h.rem(20px);

    button.closeButton {
      background-color: transparent;
      max-height: h.rem(20px);
      max-width: h.rem(20px);

      svg {
        height: h.rem(20px);
        width: h.rem(20px);
        stroke: var(--black);
        stroke-width: h.rem(2px);
      }
    }
  }

  div.sectionsContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: h.rem(0 44px 0 40px);

    //nested for specificity to override
    section.trendingSection {
      display: block;
      width: 100%;

      h4.trendingSectionTitle {
        margin-top: h.rem(25px);
      }

      &:first-child {
        h4.trendingSectionTitle {
          margin-top: 0;
        }
      }
    }

    div.trendingOption {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 1.5;
      position: relative;
      margin-top: h.rem(15px);

      div.bookmarkContainer {
        display: inline-block;
        min-width: h.rem(30px);

        .trendingOptionSave {
          justify-content: flex-start;
          margin-left: -14px;

          //nested for specificity
          button {
            max-height: h.rem(20px);
            max-width: h.rem(20px);
            margin-top: h.rem(4px);
          }
        }
      }

      .linkContainer {
        text-decoration: underline h.rem(2px);
        text-decoration-color: transparent;
        transition: color 0.125s, text-decoration-color 0.125s;
        color: #2a2a2a;

        &:hover {
          text-decoration-color: var(--accent-orange);
          text-underline-offset: h.rem(4px);
        }
      }
    }
  }
}
