@use 'assets/styles/utils/helper' as h;

.header-and-footer--banner-ad {
  --header-and-footer-ad-container-display: none;
  --header-and-footer-background-color: #2a2a2a;
  --header-and-footer-ad-container-padding: #{h.rem(10px)};
  --header-and-footer-ad-container-min-height: calc(#{h.rem(50px)} + var(--header-and-footer-ad-container-padding) * 2);

  @include h.breakpoint-m {
    --header-and-footer-ad-container-min-height: calc(#{h.rem(90px)} + var(--header-and-footer-ad-container-padding) * 2);
    --header-and-footer-ad-container-padding: 1rem;
    --header-and-footer-ad-container-display: flex;
  }

  @include h.breakpoint-l {
    --header-and-footer-ad-container-min-height: calc(#{h.rem(250px)} + var(--header-and-footer-ad-container-padding) * 2);
  }

  body.today & {
    --header-and-footer-background-color: #ebebeb;
  }

  body.noticias &,
  body.deportes &,
  body.entretenimiento &,
  body.shows &,
  body.telemundo & {
    --header-and-footer-ad-container-display: flex;
  }
}

.landing-header-footer-banner-ad {
  --landing-topbanner-background-color-loading: #f2f2f2;
  --landing-topbanner-background-color-loaded: #fff;
}

.ad-placeholder {
  --advertisement-text-color: #999;
  --advertisement-top: 40%;
  --advertisement-left: 50%;
  --advertisement-transform: translate(-50%, -50%);
  --advertisement-font-size: 12px;
  --advertisement-font-family: Arial, Helvetica, sans-serif;
}
