// Fonts

// Default fonts that are used while the specified font is loading
$founders-loading-font-family: 'Arial Narrow', Arial, serif;
$publico-loading-font-family: Georgia, Times New Roman, Times, Baskerville, serif;
$lato-loading-font-family: 'Arial Narrow', Arial, serif;

// Legacy - Tokens have been updated to provide font families
$founders-semibold-font-family: 'FoundersGroteskCond', 'Arial Narrow', Arial, sans-serif;
$founders-mono-font-family: 'FoundersGroteskMono', Courier, 'Courier New', monospace;
$publico-font-family: 'PublicoText', Georgia, 'Times New Roman', serif;
$publico-light-font-family: 'PublicoHeadline', Georgia, 'Times New Roman', serif;
$publico-light-italic-font-family: 'PublicoHeadline', Georgia, 'Times New Roman', serif;

:root {
  --founders-loading: 'Arial Narrow', Arial, serif;
  --publico-loading: Georgia, Times New Roman, Times, Baskerville, serif;
  --lato-loading: 'Arial Narrow', Arial, serif;
  --oswald-font-family: Oswald, #{var(--founders-cond)};
}
