@use 'assets/styles/utils/helper' as h;

.todayLogo {
  display: flex;
  line-height: inherit;
  padding-right: h.rem(30px);
  padding-left: 0;
  position: relative;
  align-items: center;
}

.logo {
  font-size: h.rem(20px);
  line-height: h.rem(20px);
  line-height: inherit;

  .white & {
    color: var(--white);
  }

  .black & {
    color: var(--black);
  }

  @include h.breakpoint-m {
    font-size: h.rem(30px);
    line-height: h.rem(30px);
    transition: color 0.5s;

    .active & {
      color: var(--black);
      transition: color 0.5s 1s;
    }
  }
}

.sunrise {
  margin: h.rem(20px) h.rem(7px);
  width: h.rem(34px);
  height: h.rem(20px);

  .white & {
    fill: var(--white);
  }

  .black & {
    fill: var(--orange-30);
  }

  @include h.breakpoint-m {
    width: h.rem(50px);
    height: h.rem(30px);
    margin: h.rem(25px) h.rem(10px);
    transition: margin-right 0.5s ease, fill 0.5s;

    .active & {
      fill: var(--default-color);
      transition: margin-right 0.5s ease, fill 0.5s 1s;
    }
  }
}
