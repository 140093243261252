@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

%tveProviderColumnWidth {
  width: 100%;

  @include h.breakpoint-m {
    width: t.$width-m;
    margin: 0 auto;
  }

  @include h.breakpoint-l {
    width: t.$width-l;
  }

  @include h.breakpoint-x {
    width: t.$width-x;
  }
}

.tve__provider-select {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(var(--grey-70-rgb), 0.99);
  padding: h.rem(20px 20px 300px);
  text-align: center;
  overflow: scroll;
  font-size: h.rem(18px);
  font-family: var(--publico-txt);
  color: var(--white);
  -moz-osx-font-smoothing: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  @supports (backdrop-filter: blur()) {
    background: rgba(var(--grey-70-rgb), 0.92);
    backdrop-filter: blur(50px);
  }

  @include h.breakpoint-l {
    display: flex;
    flex-flow: column nowrap;
    padding: h.rem(20px);
  }

  &__heading-h1,
  &__heading-h3 {
    line-height: 100%;
    margin: 0;
    padding: 0;
  }

  &__heading-h1 {
    font-size: h.rem(32px);
    margin: 0 0 h.rem(24px) 0;

    @include h.breakpoint-m {
      font-size: h.rem(32px);
      margin-top: h.rem(20px);
    }
  }

  &__heading-h3 {
    font-size: h.rem(24px);
    margin: 0 0 h.rem(16px) 0;

    @include h.breakpoint-m {
      font-size: h.rem(32px);
    }

    &__text {
      margin: 0 auto;
    }
  }

  &__description {
    line-height: 125%;
    padding: 0;
    margin: h.rem(12px 24px 24px);
  }

  &__search-text {
    line-height: 125%;
    padding: 0;
    margin: h.rem(32px) auto h.rem(24px);
    width: 300px;

    @include h.breakpoint-m {
      width: auto;
    }
  }

  &__button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: none;

    &__full-list {
      color: var(--tve-provider-select-action-color);

      &:hover {
        color: var(--tve-provider-select-action-hover);
      }

      &:active {
        color: var(--tve-provider-select-action-active);
      }
    }

    &__close {
      color: var(--white);
      font-size: h.rem(24px);
      position: absolute;
      top: 20px;
      right: 20px;

      @include h.breakpoint-m {
        top: 40px;
        right: 40px;
      }

      &:hover {
        opacity: var(--default-hover-opacity);
      }

      &:active {
        opacity: var(--default-active-opacity);
      }
    }
  }

  iframe {
    min-height: 400px;

    @include h.breakpoint-s-only {
      width: 100% !important;
    }
  }

  // Search Form component
  &__search-form {
    @extend %tveProviderColumnWidth;
    position: relative;

    &__input-text {
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      appearance: none;

      &__search {
        width: 100%;
        background: transparent;
        font-family: var(--founders-cond);
        font-size: h.rem(24px);
        line-height: 1;
        padding: 0 0 h.rem(12px);
        margin-bottom: h.rem(20px);
        border-bottom: 1px solid var(--white);
        border-radius: 0;
        color: var(--white);
      }
    }

    &__search-icon {
      position: absolute;
      top: h.rem(8px);
      right: 0;
      font-size: h.rem(20px);
    }

    &__button {
      color: var(--white);

      &:hover {
        opacity: var(--default-hover-opacity);
      }

      &:active {
        opacity: var(--default-active-opacity);
      }
    }
  }

  // Search Results component
  &__search-results {
    @extend %tveProviderColumnWidth;
    max-height: h.rem(336px);
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    @include h.breakpoint-l {
      min-height: h.rem(336px);
    }

    &--fill {
      max-height: unset;
    }

    &__button {
      font-family: var(--founders-cond);
      font-size: h.rem(24px);
      line-height: 1;
      display: block;
      color: var(--black);
      background-color: var(--white);
      padding: h.rem(20px);
      width: 100%;
      text-align: left;
      border-bottom: 1px solid var(--grey-40);

      &:hover {
        background-color: var(--grey-20);
      }

      &:active {
        background-color: var(--white);
      }
    }
  }

  // Map component
  &__map {
    max-width: h.rem(375px);
    margin: 0 auto h.rem(24px);
    font-family: var(--founders-cond);
    font-size: h.rem(16px);
    background-color: rgba(var(--black-rgb), 0.7);

    @include h.breakpoint-m {
      width: h.rem(375px);
    }

    &__item {
      display: inline-block;
      border-bottom: 3px solid var(--grey-50);
      padding: h.rem(10px 6px);
      width: 33.3%;

      &--active {
        border-bottom-color: var(--tve-provider-select-action-color);
      }
    }
  }

  // Featured grid component
  &__featured-grid {
    margin: 0 auto;
    display: inline-block;

    @include h.breakpoint-l {
      display: block;
    }

    &__provider {
      float: left;
      margin: 0 h.rem(3px 3px) 0;
      background-color: rgba(24, 24, 24, 1);

      @include h.breakpoint-s-only {
        &:nth-of-type(3n+1) {
          clear: left;
        }

        &:nth-of-type(3n+3) {
          margin: 0;
        }
      }

      @media only screen and (max-width: 375px) {
        width: calc(33% - ((3px * 2) / 3));
        height: h.rem(70px);
      }

      @media only screen and (min-width: 376px) and (max-width: h.$grid-m-width - 1) {
        width: h.rem(110px);
        height: h.rem(70px);
      }

      @include h.breakpoint-m {
        width: h.rem(121px);
        height: h.rem(69px);

        &:nth-of-type(5n+1) {
          clear: left;
        }

        &:nth-of-type(5n+5) {
          margin: 0;
        }
      }

      @include h.breakpoint-l {
        width: h.rem(130px);
        height: h.rem(74px);
      }

      @include h.breakpoint-x {
        width: h.rem(138px);
        height: h.rem(79px);
      }

      &__hide-mobile {
        display: none;

        @include h.breakpoint-m {
          display: block;
        }
      }
    }

    &__button {
      &__provider {
        width: 100%;
        height: 100%;
        padding: h.rem(10px);
        background-color: var(--black);

        @include h.breakpoint-m {
          padding: h.rem(12px);
        }

        @include h.breakpoint-x {
          padding: h.rem(14px);
        }

        &:hover {
          background-color: var(--grey-60);
        }

        &:active {
          background-color: var(--grey-50);
        }

        > img {
          max-width: 100%;
          height: auto;
          pointer-events: none;
        }
      }
    }
  }

  // All Options Select component
  &__select {
    @extend %tveProviderColumnWidth;
    position: relative;

    select {
      width: 100%;
      appearance: none;
      box-shadow: none !important;
      border-radius: 0;
      border: 1px solid var(--grey-50);
      padding: h.rem(8px);
      background: var(--white);
      font-family: var(--founders-mono);
      letter-spacing: -0.04em;
    }

    &__down {
      position: absolute;
      top: 10px;
      right: 10px;
      color: var(--black);
      z-index: 1;
      pointer-events: none;
    }
  }

  // Back Button component
  &__back-button {
    @extend %tveProviderColumnWidth;
    padding: 0 0 h.rem(12px) 0;
    margin-bottom: h.rem(20px);
    border-bottom: 1px solid var(--grey-60);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: h.rem(50px);

    @include h.breakpoint-m {
      padding-right: h.rem(80px); // width of button
      margin-bottom: h.rem(40px);
    }

    &__button {
      font-family: var(--founders-cond);
      font-size: h.rem(16px);
      text-transform: uppercase;
      background-color: var(--tve-provider-select-action-color);
      color: var(--black);
      padding: h.rem(10px 8px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      :first-child {
        margin: h.rem(2px) h.rem(4px) 0 0;
        font-size: h.rem(14px);
      }

      &:hover {
        background-color: var(--tve-provider-select-action-hover);
      }

      &:active {
        background-color: var(--tve-provider-select-action-active);
      }
    }

    &__text {
      flex: 1;
      padding: 0;
      margin: 0;
    }
  }

  // Loader component
  &__loader-icon {
    font-family: var(--founders-mono);
    font-size: h.rem(16px);
    letter-spacing: -0.04em;
    line-height: 1;
    position: relative;
    margin: h.rem(96px) 0;

    .icon {
      font-size: h.rem(20px);
      display: inline-block;
      margin-right: h.rem(16px);
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: .5s;

      &:first-of-type {
        animation-delay: 0s;
      }

      &:last-of-type {
        animation-delay: 1s;
        margin-right: 0;
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: .3;
  }
}
