@use 'assets/styles/utils/helper' as h;

.verticalLogo {
  font-family: var(--founders-cond);
  font-weight: normal;
  padding-right: h.rem(16px);
  padding-left: 0;
  position: relative;
  height: auto;
  display: flex;
}

.vertical {
  flex-shrink: 0;
}

.logo {
  font-size: h.rem(20px);
  line-height: 100%;
  display: block;
  margin-top: h.rem(20px);

  .white & {
    color: var(--white);
  }

  .black & {
    color: var(--black);
  }

  .hasTagline & {
    margin-top: h.rem(14px);
  }

  @include h.breakpoint-m {
    font-size: h.rem(30px);
    margin-top: h.rem(25px);
    transition: color 0.5s;

    .active & {
      color: var(--black);
      transition: color 0.5s 1s;
    }

    .hasTagline & {
      margin-top: h.rem(17px);
    }
  }
}

.tagline {
  display: block;
  padding-top: h.rem(2px);
  padding-left: h.rem(6px);
  white-space: nowrap;

  :global(.think) & {
    color: h.$think-red-40;
  }

  .white & {
    color: var(--white);
  }

  .black & {
    :global(.think) & {
      color: h.$think-red-40;
    }
  }

  @include h.breakpoint-m {
    padding-left: h.rem(10px);
    transition: color 0.5s;

    .active & {
      :global(.think) & {
        color: h.$think-red-40;
      }
      transition: color 0.5s 1s;
    }
  }
}

.peacock {
  flex-shrink: 0;
  flex-basis: h.rem(34px);
  margin: h.rem(20px 7px 0);
  height: h.rem(20px);

  .white & {
    fill: var(--white);
  }

  .black & {
    fill: var(--black);
  }

  .hasTagline & {
    margin-top: h.rem(14px);
  }

  @include h.breakpoint-m {
    flex-basis: h.rem(50px);
    height: h.rem(30px);
    margin: h.rem(25px 10px 0);
    transition: margin-right 0.5s ease, fill 0.5s;

    .fill & {
      fill: var(--default-color);
      transition: margin-right 0.5s ease, fill 0.5s 1s;
    }

    .scrolled & {
      margin-left: 0;
      margin-right: h.rem(-20px);
    }

    .hasTagline & {
      margin-top: h.rem(17px);
    }

    .active & {
      fill: var(--default-color);
      transition: margin-right 0.5s ease, fill 0.5s 1s;

      :global(.news) &,
      :global(.msnbc) & {
        fill: var(--black);
      }
    }
  }

  .feather {
    transition: transform 0.5s, opacity 0.5s;
    transform-box: fill-box;

    &:nth-child(7) { // feather 1
      transform-origin: -12% 107%;
    }

    &:nth-child(6) { // feather 2
      transform-origin: -12% 114%;
    }

    &:nth-child(5) { // feather 3
      transform-origin: 19% 121%;
    }

    &:nth-child(4) { // feather 4
      transform-origin: 98% 105%;
    }

    &:nth-child(3) { // feather 5
      transform-origin: 117% 100%;
    }

    &:nth-child(2) { // feather 6
      transform-origin: 100% 100%;
      transition: transform 0.5s, opacity 0.5s;
    }

    @include h.breakpoint-m {
      .scrolled & {
        &:nth-child(7) {
          transform: rotate(-145deg);
          opacity: 0;
        }

        &:nth-child(6) {
          transform: rotate(-110deg);
          opacity: 0;
        }

        &:nth-child(5) {
          transform: rotate(-81deg);
          opacity: 0;
        }

        &:nth-child(4) {
          transform: rotate(-67deg);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-34deg);
          opacity: 0;
        }

        &:nth-child(2) {
          // stylelint-disable-next-line
          -ms-transform: scale(2);
          transform: scale(2);
          transition: transform 0.5s 1s, opacity 0.5s 1s;
        }

        &:nth-child(1) {
          transform-origin: -12% 107%;
        }
      }
    }
  }
}
