@use 'assets/styles/utils/helper' as h;

.layout-header {
  --layout-header-default-background-color: var(--default-color);
  --layout-header-z-index: 1200000;
  --skip-link-overlay-z-index: calc(var(--layout-header-z-index) + 1);
  --main-nav-height: 60px;
  --election-nav-height: 42px;
  --default-local-insert-flex: initial;

  body.msnbc & {
    --default-local-insert-flex: 0 0 var(--default-local-insert-flex-basis);
  }

  body.news & {
    --layout-header-default-background-color: #{h.$news-blue-70};
    --default-local-insert-flex: 0 0 var(--default-local-insert-flex-basis);
  }

  body.today & {
    --default-local-insert-flex: 0 0 var(--default-local-insert-flex-basis);
  }

  body.telemundo & {
    --main-nav-height: 80px;
  }

  body.noticias & {
    --layout-header-default-background-color: var(--blue-60);
    --main-nav-height: 80px;
  }

  body.select & {
    --main-nav-height: 80px;
  }
}
