$think-red-40: #ff5042;
$mach-purple-50: #5c00d8;
$better-teal-30: #21d1aa;
$news-blue-40: #3061ff;
$news-blue-70: #102039;
$news-red-30: #fa3a3a;
$news-alt-red-10: #ffb4b4;
$global-teal-40: #7eaaad;
$global-red-40: #e40021;
$today-orange-30: #ff503c;
$today-purple-70: #380538;
$noticias-red-50: #c31010;
$noticias-blue-60: #23283c;
$msnbc-default-color: #0942fa;

$msnbc-yellow-40: #ffbe03;
$msnbc-alt-red-70: #911010;

$nbc-peacock-yellow: #fdb913;

$curator-yellow: #ff0;

$breaking-red: #cb1111;

$brandNames: (
  'better',
  'eonline',
  'globalcitizen',
  'mach',
  'msnbc',
  'news',
  'deportes',
  'localstationdeportes',
  'noticias',
  'select',
  'telemundo',
  'telemundoentretenimiento',
  'telemundoshows',
  'think',
  'today'
);

// Default gradient values
$default-gradient-value-1: #002273;
$default-gradient-value-2: #0160fa;

// Navbar
$navbar-mobile-height: 60px;
$navbar-default-height: 80px;

// Text highlight
$highlighter-yellow: #ffde81;

$live-flag-active-background-color: $news-red-30;

:root {
  --news-alt-red-10: #{$news-alt-red-10};
  --news-red-30: #{$news-red-30};
  --think-red-40: #{$think-red-40};
  --navbar-mobile-height: 60px;
  --navbar-default-height: 80px;
  --breaking-red: #{$breaking-red};
  --noticias-red-50: #{$noticias-red-50};
  --default-gradient-value-1-rgb: 0, 34, 115;
  --default-gradient-value-2-rgb: 1, 96, 250;
}
