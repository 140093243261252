@keyframes live-dot-blink {
  0%,
  100% {
    opacity: 0;
  }

  40%,
  70% {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes expand-in {
  from {
    max-height: 0;
  }

  to {
    max-height: 100px;
  }
}

@keyframes chatblog-expand-in {
  from {
    max-height: 0;
  }

  to {
    max-height: 200px;
  }
}
