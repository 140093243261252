@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.marqueeBreaking {
  color: var(--marquee--color);
  background-color: var(--marquee--background-color);
  font-family: var(--marquee--font-family);
  font-size: var(--marquee--font-size);
  font-weight: var(--marquee--font-weight);
  line-height: var(--marquee--line-height);
  min-height: var(--marquee--min-height);
  padding: var(--marquee--padding);
  position: relative;
  z-index: h.get-z-index('marquee');
  text-transform: var(--marquee--text-transform);

  @media print {
    display: none;
  }
}

.hidden {
  display: none;
}

.inner {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: var(--marquee--inner--max-width);
  width: 100%;
}

.content {
  margin: 0 var(--space-20) 0 0;
}

.icon {
  color: currentColor;
  display: inline-block;
  font-size: var(--marquee--icon--font-size);
  margin-left: var(--marquee--icon--margin-left);

  &.icon::before {
    vertical-align: unset;
  }
}

.closeButton {
  background: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  display: var(--marquee--close-button--display);
  flex: 0;
  margin-left: auto;
  padding: 0;

  svg {
    height: var(--marquee--close-button-svg--height);
    width: var(--marquee--close-button-svg--width);
    stroke: var(--marquee--close-button-svg--stroke-color);
    stroke-width: var(--marquee--close-button-svg--stroke-width);
  }
}
