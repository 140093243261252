@use 'assets/styles/utils/helper' as h;

.container {
  font-family: var(--founders-cond);
  font-weight: normal;
  line-height: inherit;
  padding-right: h.rem(30px);
  padding-left: 0;
  position: relative;
}

.logo {
  font-size: h.rem(29px);
  color: var(--black);
  margin: h.rem(0 20px);
  padding-right: h.rem(25px);
  line-height: 2.4;

  img {
    height: 30px;
  }

  @include h.breakpoint-m {
    font-size: h.rem(41px);
    line-height: 2.2;

    img {
      height: auto;
    }
  }
}
