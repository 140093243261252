@use 'assets/styles/utils/helper' as h;

.marqueeEmbed {
  line-height: 0;
  z-index: h.get-z-index('marquee');
  position: relative;
  background-color: var(--white);

  // height adjustment window messages are ignored anyway, so the
  // marquee gets an explicit static height for mobile/tablet.
  height: 134px;

  @media (min-width: 758px) {
    height: 110px;
  }
}
