@use 'assets/styles/utils/helper' as h;

.noticiasLogo {
  display: flex;
  line-height: inherit;
  padding-right: h.rem(30px);
  padding-left: 0;
  position: relative;
  align-items: center;
}

.logo {
  font-size: h.rem(20px);
  line-height: 100%;
  line-height: inherit;

  .white & {
    color: var(--white);
  }

  .black & {
    color: var(--black);
  }

  @include h.breakpoint-m {
    font-size: h.rem(30px);
    transition: color 0.5s;

    .active & {
      color: var(--black);
      transition: color 0.5s 1s;
    }
  }
}
